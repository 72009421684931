<template>
  <div>
    <p class="label" v-if="label">{{ label }}</p>
    <div class="progress">
      <div :class="['progress-bar', passwordClass]" role="progressbar" :style="`width: ${passwordStrength}%`" :aria-valuenow="passwordStrength" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'

export default {
  props: {
    label: String,
    password: {
      required: true,
      type: String
    }
  },

  computed: {
    passwordStrength () {
      const score = zxcvbn(this.password).score

      switch (score) {
        case 0:
          return 0
        case 1:
          return 25
        case 2:
          return 50
        case 3:
          return 75
        case 4:
          return 100
        default:
          return 0
      }
    },

    passwordClass () {
      switch (this.passwordStrength) {
        case 0:
        case 25:
          return 'bg-danger'
        case 50:
          return 'bg-warning'
        case 75:
        case 100:
          return 'bg-success'
        default:
          return 'bg-danger'
      }
    }
  }
}
</script>
